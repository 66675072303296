import { useContext, useMemo } from "react";

import { filterBooleanTs } from "~utils/filterBooleanTs";
import { getIsInEditor } from "~utils/storyblok/getIsInEditor";

import { PageQueryContext } from "~context/PageQueryContext";

import type { RelationsCarouselProps, ResolvedCarouselItemShape } from ".";
import type {
  SbRelationsGraphQlNode,
  StoryblokEntry,
  StoryblokRelationsNodeType,
} from "~types/storyblok.types";

export interface TransformedRelationObject {
  title?: string;
}

export interface UseStoryblokRelationsArgs {
  items?: RelationsCarouselProps["items"];
  transformApiEntry: (content: StoryblokEntry) => ResolvedCarouselItemShape;
  transformGraphQlNode: (
    content: SbRelationsGraphQlNode
  ) => ResolvedCarouselItemShape;
  isAlphabetized?: boolean;
  storyblokRelationsNodeType: StoryblokRelationsNodeType;
}

function alphabetize<TOutputObjectShape>(
  objects: Array<TOutputObjectShape & TransformedRelationObject>
): Array<TOutputObjectShape> {
  return objects.sort((a, b) => {
    if (a.title && b.title && a.title < b.title) {
      return -1;
    }
    if (a.title && b.title && a.title > b.title) {
      return 1;
    }

    return 0;
  });
}

/**
 * Handles parsing relations that may arrive in 2 different formats,
 * in production, and in the Storyblok visual editor
 */
export function useStoryblokRelationsCarousel({
  items,
  transformApiEntry,
  transformGraphQlNode,
  storyblokRelationsNodeType,
  isAlphabetized,
}: UseStoryblokRelationsArgs): Array<ResolvedCarouselItemShape> | null {
  /**
   * Get relations from parent page query response,
   * which is made available via context provider.
   */
  const { data } = useContext(PageQueryContext);

  const graphQlRelationsResponse = data
    ? data[storyblokRelationsNodeType]
    : null;

  const { nodes } = graphQlRelationsResponse || {};

  return useMemo(() => {
    if (!Array.isArray(items)) return null;

    /**
     * ----------------------------------------------
     * In Storyblok visual editor, we can expect `content`
     * to be an array of objects of type `StoryblokEntry`
     * ----------------------------------------------
     */

    if (getIsInEditor()) {
      const itemsWithResolvedRelations = items.map((item) => {
        return {
          ...transformApiEntry(item.content as StoryblokEntry),
          animation: item.animation,
        };
      });

      if (isAlphabetized && itemsWithResolvedRelations) {
        return alphabetize<ResolvedCarouselItemShape>(
          itemsWithResolvedRelations
        );
      }

      return itemsWithResolvedRelations;
    }

    /**
     * ----------------------------------------------
     * In production, we can expect an array of UUIDs,
     * where the content is queried in the parent page
     * GraphQL query and we have to resolve the data
     * using the UUIDs manually.
     * ----------------------------------------------
     */

    if (!Array.isArray(nodes)) return null;

    const nodesInScope = nodes.filter((filteredNode) => {
      return (
        filteredNode.uuid &&
        items?.find((contentNode) => {
          return (contentNode.content as string) === filteredNode.uuid;
        })
      );
    });

    const itemsWithResolvedRelations = items
      ?.map((item) => {
        const correspondingNode = nodesInScope?.find((node) => {
          return (item.content as string) === node.uuid;
        });

        if (!correspondingNode) return null;

        return {
          ...transformGraphQlNode(correspondingNode),
          animation: item.animation,
        };
      })
      .filter(filterBooleanTs);

    /**
     * ----------------------------------------------
     * Optionally sort output objects by title key
     * ----------------------------------------------
     */

    if (isAlphabetized && itemsWithResolvedRelations) {
      return alphabetize<ResolvedCarouselItemShape>(itemsWithResolvedRelations);
    }

    return itemsWithResolvedRelations;
  }, [items, nodes, transformGraphQlNode, isAlphabetized, transformApiEntry]);
}
